body {
  padding: 0;
  margin: 0;
  background: #f9fbf8;
}

* {
  box-sizing: border-box;
  font-family: Brandon Grotesque, Helvetica sans-serif;
}

a { text-decoration: none;}