@font-face {
	font-family: Brandon Grotesque;
	font-weight: 300;
	src: url("./Brandon_light.otf") format("opentype");
}

@font-face {
	font-family: Brandon Grotesque;
	font-weight: 400;
	src: url("./Brandon_reg.otf") format("opentype");
}

@font-face {
	font-family: Brandon Grotesque;
	font-weight: 400;
	font-style: italic;
	src: url("./Brandon_reg_it.otf") format("opentype");
}

@font-face {
	font-family: Brandon Grotesque;
	font-weight: 600;
	src: url("./Brandon_bld.otf") format("opentype");
}
