/* Fix for scrollbars */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

.loading-container {
  animation-duration: 2s;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loading;
  animation-timing-function: ease-in-out;
  background: linear-gradient(to right, #eee 8%, #ccc 38%, #eee 54%);
  background-size: cover;
}

@keyframes loading {
  from {
    background-position: -468px 0;
  }
  to {
    background-position: 468px 0;
  }
}